import React, { useEffect } from 'react';

import i18n from 'i18n-js';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import TagManager from 'react-gtm-module';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import '@santeacademie/synapse-composition-react/dist/synapse.min.css';

import '@styles/base/_base.css';
import '@styles/base/_fonts.css';

import fr from '@locales/fr';
import CookieStoreProvider from '@stores/CookieStoreContext';
import Html from '@screens/Html';
// import { MobileAppBanner } from '@components/MobileAppBanner';
// import { datadogRum } from "@datadog/browser-rum";

i18n.translations = {
    fr,
};
i18n.defaultLocale = 'fr';
i18n.fallbacks = true;

const queryClient = new QueryClient();

const MobileAppBanner = dynamic(() => import('@components/MobileAppBanner').then((mod) => mod.MobileAppBanner), {
    ssr: false,
});

const App = (appProps: AppProps): JSX.Element => {
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_GTM_ID && process.env.NEXT_PUBLIC_GTM_AUTH && process.env.NEXT_PUBLIC_GTM_PREVIEW) {
            TagManager.initialize({
                gtmId: process.env.NEXT_PUBLIC_GTM_ID,
                auth: process.env.NEXT_PUBLIC_GTM_AUTH,
                preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
            });
        }

        if (process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN && process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID) {
            // datadogRum.init({
            //     clientToken: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN,
            //     applicationId: process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID,
            //     site: 'datadoghq.eu',
            //     service: 'contentplayer-web',
            //     env: process.env.NEXT_PUBLIC_ENVIRONMENT,
            //     version: process.env.NEXT_PUBLIC_VERSION,
            //     sessionSampleRate: 100,
            //     sessionReplaySampleRate: 100,
            //     trackUserInteractions: true,
            //     trackResources: true,
            //     trackLongTasks: true,
            //     defaultPrivacyLevel: "mask-user-input",
            //     allowedTracingUrls: [
            //         { match: "https://crossguard.santeacademie.com", propagatorTypes: ["datadog"] },
            //         { match: "https://contentstudio.santeacademie.com", propagatorTypes: ["datadog"] }
            //     ],
            // });
        }
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <CookieStoreProvider>
                <Html {...appProps} />
                <ReactQueryDevtools initialIsOpen={false} />
                <MobileAppBanner />
            </CookieStoreProvider>
        </QueryClientProvider>
    );
};

export default App;
