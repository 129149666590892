import React, { useContext, ReactNode, useEffect } from 'react';

import { ContentStudioApiContext } from '@santeacademie/contentstudio-api-client';

import { ClientContext } from '@stores/ClientContext';
import ClientNoteRouter from '@components/ClientNote/ClientNoteRouter';
import { CookieStoreContext } from '@stores/CookieStoreContext';
import { isPrivatePath } from '@constants/security';
// import { datadogRum } from '@datadog/browser-rum';

interface IBodyProps {
    children: ReactNode;
}

const Body = ({ children }: IBodyProps): JSX.Element => {
    const { client } = useContext(ClientContext);
    const { learnerIdentifierCookie, removeLearnerIdentifierCookie } = useContext(CookieStoreContext);
    const { logout } = useContext(ContentStudioApiContext);

    useEffect(() => {
        (async () => {
            if (typeof learnerIdentifierCookie !== 'object' || learnerIdentifierCookie.id == null) {
                try {
                    await logout({});
                    removeLearnerIdentifierCookie();
                } catch (error: any) {}

                if (isPrivatePath()) {
                    document.location.href = '/login';
                }
            }
            if (learnerIdentifierCookie && learnerIdentifierCookie.id) {
                // datadogRum.setUser({
                //     id: learnerIdentifierCookie.id.toString(),
                //     email: learnerIdentifierCookie.email,
                //     name: learnerIdentifierCookie.firstname + " " + learnerIdentifierCookie.lastname,
                // })
            }
        })();
    }, [learnerIdentifierCookie]);

    return (
        <>
            {client && <ClientNoteRouter notes={client.notes} />}
            {children}
        </>
    );
};

export default Body;
